/* Centering the game container in the browser window without affecting its layout */
body {
  margin: 0;  /* Remove default margin */
  height: 100vh;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  overflow: hidden; /* Hide overflow */
  background-color: #302E2B;
  /* background-color: rgb(190, 179, 115); */
}

.app-wrapper {
  /* position: absolute; */
  margin-bottom: 5vh;
  width: 90vw; /* Adjust width as needed */
  /* height: 90vh; Adjust height as needed */
  height: 70vh; 
  /* margin: auto; */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); /* Optional: Adds shadow for depth */
  border-radius: 15px; /* Optional: Adds rounded corners */
  /* background-color: #302E2B; Optional: Change background color */
  background-color: rgb(144, 151, 72);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.app-wrapper .game-info {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin-top: 0;
  width: 100%;
  z-index: 10;
}

.current-turn {
  /* width: 90%; */
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5); */
  border-radius: 15px;
  background: #EBEBD0; /* or any color you prefer */
  padding: 1%;
  text-align: center;

  opacity: 0.8;
  font-weight: 400;
  font-size: 1.5hw;
}

.game-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80vh; /* Adjust as needed */
  height: 85vh; /* Adjust as needed */
  /* padding: 20%; Optional */
}

.button-group {
  /* margin-top: 5%; */
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;/* Adjust the space between buttons */
}

.button {
  /* bottom: 10%; */
  width: auto;
  height: 8vh;
  padding: 1vh;
  border-radius: 2vw;
  margin-top: 15vh; /* Space above the START button */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  opacity: 0.8;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  font-size: 3vh;
  color: darkred;
  background-color: gold;
  border: solid gray;/* box-shadow: 0 0 10px gold; */
  /* Add additional styling for the button */
}

.modal {
  position: absolute;
  top: 3%;
  left: 3%;
  width: auto;
  height: 5vh;
  margin-bottom: 1%;
  border-radius: 0.5vw;
  opacity: 0.8;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 2vh;
  color: black;
  background-color: #4f4c48;
  border: solid gray;
}

.button .refresh {
  color: black;
  background-color: #4f4c48;
  border: solid gray;/* box-shadow: 0 0 10px gold; */
  /* Add additional styling for the button */
}

.game-container {
  /* position: absolute; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20vw;
  max-width: 90vw; /* Max width to avoid overflow */
  max-height: 90vh; /* Max height to avoid overflow */
  border: solid darkgray; 
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5);
  background-color: #4d6a2b;
}

.game-container.game-over {
  opacity: 0.5; 
  pointer-events: none; /* Disables all interactions */
}

.selection-message {
  /* position: absolute; */
  /* top: 20%; /* Adjust as needed */
  padding: 3%;
  top: 10%;
  left: 50%;
  z-index: 10; /* Ensure it appears above other elements */
  color: #300d0b; /* Style as needed */
  font-size: 1.5hv;
  font-weight: bold;
  /* ...other styles... */
}

.game-over-message {
  font-weight: bold;
  color: gold; /* Example color */
  font-size: 2em; /* Example font size */
  margin-top: 20px; /* Example margin */
  /* Additional styling as needed */
}

.game-content {
  display: flex;
  justify-content: center; /* Distribute space between elements */
  align-items: center;
  width: 100%; /* Full width of the container */
}

.rows-container {
  margin-top: 1%;
  margin-bottom: 1%;
  background-origin: flex;
  flex-direction: column;
  align-items: center;
  gap: 1%; /*Responsive gap between rows */
}

.row-upper, .row-lower {
  display: flex;
  justify-content: center;
}

.row-upper .circle {
  background-color:#575452;
  color: #EBEBD0;
}

.row-lower .circle {
  background-color: #EBEBD0;
  color: #575452;
}

.circle, .house {
  position: relative;
  width: 7vw; /* Responsive width */
  height: 7vw; /* Responsive height, maintaining aspect ratio */
  border-radius: 50%;
  /* background-color: #EBEBD0; */
  margin: 0.5vw; /* Responsive margin */
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid darkgrey;
  box-shadow: inset 0 0 0.3vw #4d6a2b;
  font-size: 2.5vw; /* Responsive font size for seed count */
  font-weight: bold;
}

.circle.selected {
  background-color: darkgoldenrod;
}

.flipped {
  transform: rotate(180deg);
  /* Ensure the text is still positioned correctly after flipping */
  position: absolute;
  width: 100%;
  text-align: center;
}

.house {
  width: 10vw; /* Larger size for houses */
  height: 10vw;
}

.upper-house {
  background-color: #575452;
  color: #EBEBD0;
}

.lower-house{
  background-color: #EBEBD0;
  color: #575452;
}

.circle:hover, .button:hover {
  cursor: pointer;
}

.circle-index {
  opacity: 0; /* increase this value to show index number. e.g. 0.5 */
  position: absolute;
  top: 1vw;
  left: 1vw;
  font-size: 1.5vw; /* Smaller font size for clarity */
  color: black;
  /* z-index: 2; */
}

.trademark-section {
  position: fixed;
  left: 1%; /* Adjust as needed */
  bottom: 1%; /* Adjust as needed */
  color: #888; /* Subtle text color */
  font-size: x-small; /* Small but readable font size */
  font-family: Arial, sans-serif; /* A simple and clean font */
  opacity: 0.7; /* Slightly transparent for subtlety */
}

.trademark-section a {
  color: #888; /* Same as the trademark text */
  text-decoration: none; /* Remove underline */
  opacity: 0.7; /* Consistent with the trademark's styling */
}

.trademark-section a:hover, .trademark-section a:focus {
  text-decoration: underline; /* Add underline on hover for interactivity */
  opacity: 1; /* Increase opacity for emphasis */
}



/* @media screen and (orientation: portrait) {
  body {
    transform: rotate(90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */