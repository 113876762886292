.hand-cursor {
    position: absolute;
    width: 4%; /* Example size */
    height: auto;
    /* background-size: ; */
    pointer-events: none; /* Ensures it doesn't interfere with clicks */
    transition: top 0.25s, left 0.25s; /*Smooth transition for movement*/
    z-index: 2;
  }

  @keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    10% { transform: translate(-1px, -2px) rotate(-1deg); }
    20% { transform: translate(-3px, 0px) rotate(1deg); }
    30% { transform: translate(3px, 2px) rotate(0deg); }
    40% { transform: translate(1px, -1px) rotate(1deg); }
    50% { transform: translate(-1px, 2px) rotate(-1deg); }
    60% { transform: translate(-3px, 1px) rotate(0deg); }
    70% { transform: translate(3px, 1px) rotate(-1deg); }
    80% { transform: translate(-1px, -1px) rotate(1deg); }
    90% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  
  .hand-cursor.shake-animation {
    animation: shake 0.5s ease-in-out;
    /* animation-iteration-count: infinite; */
  }

  @keyframes shake-flipped {
    0% { transform: translate(1px, 1px) rotate(180deg); }
    10% { transform: translate(-1px, -2px) rotate(179deg); }
    20% { transform: translate(-3px, 0px) rotate(181deg); }
    30% { transform: translate(3px, 2px) rotate(180deg); }
    40% { transform: translate(1px, -1px) rotate(179deg); }
    50% { transform: translate(-1px, 2px) rotate(181deg); }
    60% { transform: translate(-3px, 1px) rotate(180deg); }
    70% { transform: translate(3px, 1px) rotate(181deg); }
    80% { transform: translate(-1px, -1px) rotate(179deg); }
    90% { transform: translate(1px, 2px) rotate(180deg); }
    100% { transform: translate(1px, -2px) rotate(181deg); }
  }
  
  .hand-cursor.shake-animation-flipped {
    animation: shake-flipped 0.5s ease-in-out;
  }
  